<template>
	<div class="w-100">
		<!-- Day-to-day Support Options -->
		<Checkboxes
			:values="dayToDaySupport"
			name="support"
			getter="getSupport"
			updateMethod="updateSupport"
			title="Are any of these people involved in your day-to-day life?"
			subtitle="Please select all that apply"
		/>

		<!-- Other Support Option -->
		<form-row v-if="otherSupport">
			<form-input
				block
				name="otherSupport"
				dataStore="form"
				updateMethod="updateOtherSupport"
				label="Other"
			/>
		</form-row>
	</div>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import Checkboxes from '@/components/form/checkbox/group'
import formRow from '@/components/form/form-row'
import formInput from '@/components/form/form-input'

export default {
	components: {
		Checkboxes,
		formRow,
		formInput
	},
	data() {
    return {
			otherSupport: null,
			dayToDaySupport: [
				{
					name: "Social worker",
					id: "social-worker"
				}, {
					name: "Support worker or care worker",
					id: "support-worker"
				}, {
					name: "Housing / homeless officer",
					id: "housing-officer"
				}, {
					name: "Someone from mental health services",
					id: "mental-health-services"
				}, {
					name: "Drugs/Alcohol Worker",
					id: "drugs-alcohol-worker"
				}, {
					name: "Another service - please specify",
					id: "another-service"
				}, {
					name: "No, none of these",
					id: "none-of-these"
				}
			]
    }
  },
	computed: {
		...mapGetters([
			'getSupport',
			'getOtherSupport'
		])
	},
	watch: {
    getSupport(value) {
      if (value.includes('Another service - please specify')) {
				this.otherSupport = true
				this.$announcer.polite('The form has been updated.')

      } else {
				this.otherSupport = false
				this.$announcer.polite('The form has been updated.')
				
				// Clear other involvement Section if switching between radio buttons.
				if ( this.getOtherSupport.length ) {
					this.$store.dispatch('clearOtherSupport')
				}

				// Remove checked state from local store
				const id = document.getElementById('none-of-these')
				id.checked = false
			}

			// Clear checkboxes based on options
			if ( value.length > 1 && value.includes('No, none of these') ) {
				if ( value.indexOf("No, none of these") === 0 ) {
					// If other is selected and values after are valid
					this.$store.dispatch('clearSupportOptions', "No, none of these")
				} else {
					// If values are selected and other is selected
					this.$store.dispatch('clearSupport', ["No, none of these"])
				}
			}
		}
	},
	methods: {
		checkState() {
			// Check stored state
			if (this.getSupport.length && this.getSupport.includes('Another service - please specify')) {
				this.otherSupport = true
			} else {
				this.$store.dispatch('clearOtherSupport')
			}
		},
	},
	mounted() {
		this.checkState()
	}
}
</script>
