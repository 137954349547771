<template>
	<div class="w-100">
		<!-- Intoxicants Used Options -->
		<Checkboxes
			:values="intoxicants"
			name="intoxicantsUsed"
			getter="getIntoxicantsUsed"
			updateMethod="updateIntoxicantsUsed"
			title="Do you drink alcohol, use cigarettes, take drugs or vape?"
			subtitle="We will not tell anyone what you tell us today, unless we are worried about you or someone else. Please select all that apply"
		/>

		<!-- Internet tech currently used Options -->
		<Checkboxes
			:values="internetTech"
			name="internetTechCurrentlyUsed"
			getter="getInternetTechCurrentlyUsed"
			updateMethod="updateInternetTechCurrentlyUsed"
			title="Do you use any of the following websites or apps?"
			subtitle="Please select all that apply."
		/>
	</div>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import Checkboxes from '@/components/form/checkbox/group'

export default {
	components: {
		Checkboxes,
	},
	data() {
		return {
			intoxicants: [
				{
					name: "Alcohol",
					id: "alcohol"
				}, {
					name: "Cigarettes",
					id: "cigarettes"
				}, {
					name: "Vaping",
					id: "vaping"
				}, {
					name: "Drugs",
					id: "drugs"
				}, {
					name: "No",
					id: "no-intoxicants"
				}
			],
			internetTech: [
				{
					name: "Social Media",
					id: "social-media"
				}, {
					name: "Dating Apps / sites",
					id: "dating-sites"
				}, {
					name: "Gambling sites",
					id: "gambling-sites"
				}, {
					name: "Porn sites",
					id: "porn-sites"
				}, {
					name: "No",
					id: "no-sites"
				}
			]
		}
	},
	computed: {
		...mapGetters([
			'getIntoxicantsUsed',
			'getInternetTechCurrentlyUsed'
		])
	},
	watch: {
    getInternetTechCurrentlyUsed(value) {
      if (value.includes('No')) {
				// Keep checked
      } else {
				// Remove checked state from local store
				const id = document.getElementById('no-sites')
				id.checked = false
			}

			// Clear checkboxes based on options
			if ( value.length > 1 && value.includes('No') ) {
				if ( value.indexOf("No") === 0 ) {
					// If other is selected and values after are valid
					this.$store.dispatch('clearInternetTechCurrentlyUsedOptions', "No")
				} else {
					// If values are selected and other is selected
					this.$store.dispatch('clearInternetTechCurrentlyUsed', ["No"])
				}
			}
		},
		getIntoxicantsUsed(value) {
      if (value.includes('No')) {
				// Keep checked
      } else {
				// Remove checked state from local store
				const id = document.getElementById('no-intoxicants')
				id.checked = false
			}

			// Clear checkboxes based on options
			if ( value.length > 1 && value.includes('No') ) {
				if ( value.indexOf("No") === 0 ) {
					// If other is selected and values after are valid
					this.$store.dispatch('clearIntoxicantsUsedOptions', "No")
				} else {
					// If values are selected and other is selected
					this.$store.dispatch('clearIntoxicantsUsed', ["No"])
				}
			}
		}
	},
}
</script>