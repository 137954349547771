<template>
  <div>
		<page-header
			title="Your life"
		>
			<template v-slot:content>
				Next we'll ask a few questions about your life, what you do and who you live with. This is to help us build a fuller picture of you and your life.
			</template>
		</page-header>
		<page-container>
			<form autocomplete="off" @submit.prevent="nextStep">
				<!-- Employment Options -->
				<employment />
				<!-- Living Options -->
				<living />
				<!-- Day-to-day Support Options -->
				<support />
				<!-- Lifestyle Choices Options -->
				<lifestyle />
				
				<form-submit title="Continue to your mental health" />
			</form>
		</page-container>
  </div>
</template>

<script>
// Mixins
import mixpanelTracking from '@/mixins/mixpanel'

// Form Components
import pageHeader from '@/components/general/page-header'
import pageContainer from '@/components/general/page-container'
import formSubmit from '@/components/form/form-submit'

// Steps Components
import Employment from '@/components/steps/your-life/employment'
import Living from '@/components/steps/your-life/living'
import Support from '@/components/steps/your-life/support'
import Lifestyle from '@/components/steps/your-life/lifestyle'

export default {
	mixins: [ mixpanelTracking ],
	components: {
		pageHeader,
		pageContainer,
		formSubmit,
		Employment,
		Living,
		Support,
		Lifestyle
	},
	methods: {
		async nextStep() {
			// Form is valid as non-mandatory - remove loading state
			await this.$store.commit('loading', false)

			// Set stepD to complete
			await this.$store.commit('completeStepD', true)

			// Proceed to next step
			await this.$router.push(
				this.$GLOBALS.PATH_YOUR_MENTAL_HEALTH
			)
		}
	}
}
</script>
