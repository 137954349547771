<template>
	<div class="w-100">
		<!-- Day to day -->
		<Checkboxes
			:values="employmentData"
			name="employment"
			getter="getEmployment"
			updateMethod="updateEmployment"
			title="What do you do in your day-to-day life?"
			subtitle="Please select all that apply"
		/>

		<!-- Day's missed -->
		<Radios
			v-if="showMissed"
			:values="daysMissed"
			name="missedEducation"
			getter="getMissedEducation"
			updateMethod="updateMissedEducation"
			title="Have you missed any days of education in the last 6 months?"
		/>

		<!-- How many day's missed -->
		<Radios
			v-if="showDaysMissed"
			:values="howManyDays"
			name="daysMissed"
			getter="getDaysMissed"
			updateMethod="updateDaysMissed"
			title="How many days of education have you missed?"
		/>
	</div>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import Checkboxes from '@/components/form/checkbox/group'
import Radios from '@/components/form/radio/group'

export default {
	components: {
		Checkboxes,
		Radios
	},
	data() {
    return {
			showMissed: null,
			showDaysMissed: null,
			employmentData: [
				{
					name: "Working",
					id: "working"
				}, {
					name: "In school",
					id: "school"
				}, {
					name: "At college or university",
					id: "col-or-uni"
				}, {
					name: "Volunteering",
					id: "volunteering"
				}, {
					name: "None of these currently",
					id: "none-employment"
				}
			],
			daysMissed: [
				{
					name: "Yes",
					id: "missed-yes"
				}, {
					name: "No",
					id: "missed-no"
				}
			],
			howManyDays: [
				{
					name: "5 days or less",
					id: "5-days-or-less"
				}, {
					name: "6-15 day",
					id: "6-15-days"
				}, {
					name: "16 days or more",
					id: "16-days-or-more"
				},
			]
    }
  },
	computed: {
		...mapGetters([
			'getEmployment',
			'getMissedEducation',
			'getDaysMissed'
		])
	},
	watch: {
    getEmployment(value) {
      if (value.includes('In school') || value.includes('At college or university')) {
				this.showMissed = true
				this.$announcer.polite('The form has been updated.')
      } else {
				this.showMissed = false
				
				// Clear missed Sections if switching between radio buttons.
				if ( this.showMissed || this.showDaysMissed ) {
					this.$store.dispatch('clearMissedEducation')
				}
			}

			// Clear checkboxes based on options
			if ( value.length > 1 && value.includes('None of these currently') ) {
				if ( value.indexOf("None of these currently") === 0 ) {
					// If other is selected and values after are valid
					this.$store.dispatch('clearEmploymentOptions', "None of these currently")
				} else {
					// If values are selected and other is selected
					this.$store.dispatch('clearEmployment', ["None of these currently"])
				}
			}
		},
		getMissedEducation(value) {
      if (value === 'Yes') {
				this.showDaysMissed = true
				this.$announcer.polite('The form has been updated.')
      } else {
				this.showDaysMissed = false

				// Clear missed Sections if switching between radio buttons.
				if ( this.showMissed || this.showDaysMissed ) {
					this.$store.dispatch('clearMissedEducation')
				}
			}
    },
	},
	methods: {
		checkState() {
			// Check stored state
			if ( this.getMissedEducation.length ) {
				this.showMissed = true
			}

			// Check stored state
			if ( this.getDaysMissed.length ) {
				this.showDaysMissed = true
			}
		},
	},
	mounted() {
		this.checkState()
	}
}
</script>
