<template>
	<div class="w-100">
		<!-- Living Options -->
		<Radios
			:values="livingData"
			name="living"
			getter="getLiving"
			updateMethod="updateLiving"
			title="Who do you live with?"
		/>

		<!-- Other Living option -->
		<form-row v-if="otherLiving">
			<form-input
				block
				name="otherLiving"
				dataStore="form"
				updateMethod="updateOtherLiving"
				label="Other Living Arrangements"
			/>
		</form-row>
	</div>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import Radios from '@/components/form/radio/group'
import formRow from '@/components/form/form-row'
import formInput from '@/components/form/form-input'

export default {
	components: {
		Radios,
		formRow,
		formInput
	},
	data() {
    return {
			otherLiving: null,
			livingData: [
				{
					name: "With family",
					id: "with-family"
				}, {
					name: "With friends",
					id: "with-friends"
				}, {
					name: "With my partner",
					id: "with-partner"
				}, {
					name: "With my carers",
					id: "with-carers"
				}, {
					name: "With students",
					id: "with-students"
				}, {
					name: "I live by myself",
					id: "live-by-myself"
				}, {
					name: "In a hostel, refuge or B&B",
					id: "hostel-or-bnb"
				}, {
					name: "Other",
					id: "other-living-condition"
				},
			]
    }
  },
	computed: {
		...mapGetters([
			'getLiving',
			'getOtherLiving'
		])
	},
	watch: {
    getLiving(value) {
      if (value === 'Other') {
				this.otherLiving = true
				this.$announcer.polite('The form has been updated.')

      } else {
				this.otherLiving = false
				this.$announcer.polite('The form has been updated.')
				
				// Clear other living Section if switching between radio buttons.
				if ( this.getOtherLiving.length ) {
					this.$store.dispatch('clearOtherLiving')
				}
			}
		}
	},
	methods: {
		checkState() {
			// Check stored state
			if ( this.getLiving.length && this.getLiving === 'Other' ) {
				this.otherLiving = true
			}  else {
				this.$store.dispatch('clearOtherLiving')
			}
		},
	},
	mounted() {
		this.checkState()
	}
}
</script>
